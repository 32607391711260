module.exports = [{
      plugin: require('/opt/ploneconf-gatsby/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/ploneconf-gatsby/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-140389816-1","head":true,"anonymize":true,"respectDNT":true,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"2019.ploneconf.org"},
    },{
      plugin: require('/opt/ploneconf-gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
