import React from 'react';

const PloneSVG = () => (
  <svg viewBox="0 0 127 33" version="1.1">
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="footer"
        transform="translate(-408.000000, -334.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="plone-logo">
          <g transform="translate(408.000000, 333.000000)">
            <path
              d="M53.2185463,19.9550383 L47.6875864,19.9550383 L47.6875864,29 L44,29 L44,6 L53.2177069,6 C57.9629666,6 61,8.97209752 61,12.9775192 C61,16.9829408 57.9629666,19.9550383 53.2185463,19.9550383 Z M53.5323045,9 L48,9 L48,17 L53.5323045,17 C56.191647,17 58,15.5483971 58,13.0170142 C58,10.4848013 56.191647,9 53.5323045,9 Z"
              id="Shape"
            />
            <path
              d="M68.2280637,28.641 C65.3759999,28.641 64.099,26.5409931 64.099,24.1193134 L64.099,5.641 L67.1293178,5.641 L67.1293178,23.9255155 C67.1293178,25.1859962 67.5435391,25.8309302 68.7620951,25.8309302 L70.099,25.8309302 L70.099,28.641 L68.2280637,28.641 Z"
              id="Path"
            />
            <path
              d="M85.0151094,27.0348683 C83.9213471,28.1495168 82.2668688,29 79.9825006,29 C77.69976,29 76.0770204,28.147906 74.9848857,27.0348683 C73.3971399,25.3983324 73,23.3003127 73,20.4838924 C73,17.6996873 73.3971399,15.602473 74.9848857,13.9651317 C76.0770204,12.8512886 77.69976,12 79.9825006,12 C82.2668688,12 83.9213471,12.8528994 85.0151094,13.9651317 C86.6028552,15.602473 86.999995,17.6996873 86.999995,20.4838924 C87.0016227,23.3011181 86.6028552,25.397527 85.0151094,27.0348683 Z M82.8808802,15.9478331 C82.2733805,15.3274478 81.4426855,15 80.4831032,15 C79.5250926,15 78.7258336,15.3258427 78.117548,15.9478331 C77.1273156,16.9598716 77,18.6894061 77,20.4839486 C77,22.2784912 77.1273156,24.0096308 78.117548,25.0208668 C78.7250477,25.6396469 79.5250926,26 80.4831032,26 C81.4426855,26 82.2725946,25.641252 82.8808802,25.0208668 C83.8726844,24.0080257 84,22.2784912 84,20.4839486 C84,18.6894061 83.8726844,16.959069 82.8808802,15.9478331 Z"
              id="Shape"
            />
            <path
              d="M100.62434,28.641 L100.62434,18.4018914 C100.62434,15.7832478 99.0914011,14.6564325 97.1505168,14.6564325 C95.2096324,14.6564325 93.5728228,15.8158382 93.5728228,18.4018914 L93.5728228,28.6401852 L90.099,28.6401852 L90.099,11.8398018 L93.5041337,11.8398018 L93.5041337,13.5296173 C94.6978153,12.2699959 96.3656188,11.641 98.102949,11.641 C99.8746238,11.641 101.340548,12.2040002 102.36167,13.1654189 C103.690216,14.4242255 104.099,16.0146401 104.099,17.9032574 L104.099,28.6401852 L100.62434,28.641 L100.62434,28.641 Z"
              id="Path"
            />
            <path
              d="M110.287855,21.4672636 C110.287855,24.3158992 111.785682,26.1175384 114.487534,26.1175384 C116.342406,26.1175384 117.320677,25.5948456 118.493481,24.4141558 L120.609332,26.4123081 C118.916171,28.1148854 117.321478,29 114.42349,29 C110.287855,29 107,26.8053345 107,20.4838924 C107,15.1111901 109.767498,12 114,12 C118.427836,12 121,15.275488 121,19.9934148 L121,21.4672636 L110.287855,21.4672636 Z M117.568964,16.9249677 C117.078292,15.7828498 115.973903,15 114.499623,15 C113.026852,15 111.890003,15.7828498 111.399331,16.9249677 C111.09285,17.6168915 111.03095,18.0678248 111,19 L118,19 C117.96754,18.0678248 117.876955,17.6161523 117.568964,16.9249677 Z"
              id="Shape"
            />
            <circle id="Oval" cx="14.599" cy="10.141" r="3.5" />
            <path
              d="M25.099,17.1410002 C25.099,19.0759012 23.5334616,20.641 21.5982049,20.641 C19.6685139,20.641 18.099,19.0743107 18.099,17.1410002 C18.099,15.2060991 19.669309,13.6410003 21.5982049,13.6410003 C23.5350518,13.640205 25.099,15.2068943 25.099,17.1410002 Z"
              id="Path"
            />
            <circle id="Oval" cx="14.599" cy="24.141" r="3.5" />
            <path
              d="M16.4995944,1 C7.38758756,1.00081109 0.000811109746,8.38740599 0,17.5 C0.000811109746,26.6117829 7.38758756,33.9983778 16.4995944,34 C25.6124124,33.9983778 32.9983778,26.6134051 33,17.5 C32.9991889,8.38740599 25.6132235,1.00081109 16.4995944,1 Z M26.1914407,26.192126 C23.8335701,28.547661 20.5942074,29.9991972 16.9991972,30 C13.404187,29.9991972 10.1648243,28.5460553 7.80695362,26.192126 C5.4522942,23.8341825 4.00080282,20.5947198 4,17.0004014 C4.00080282,13.4052802 5.4522942,10.1658175 7.80775644,7.80787402 C10.1656271,5.45233905 13.4049898,4.00080284 17,4 C20.5950102,4.00080284 23.8343729,5.45314189 26.1922436,7.80787402 C28.5477058,10.1666203 29.9991972,13.4060831 30,17.0004014 C29.9983944,20.5947198 28.546903,23.8341825 26.1914407,26.192126 Z"
              id="Shape"
            />
            <path
              d="M124.99631,9 C123.893911,9 123,9.89575646 123,11 C123.001845,12.1042435 123.893911,13 124.99631,13 C126.102399,13 127,12.1042435 127,11 C127,9.89575646 126.102399,9 124.99631,9 Z M125.496757,12 C124.668919,11.9983788 124,11.3296406 124,10.5004053 C124.001622,9.67279114 124.668919,9.00162118 125.496757,9 C126.326216,9.00162118 126.998378,9.67116995 127,10.5004053 C126.997568,11.3296406 126.326216,11.9983788 125.496757,12 Z"
              id="Shape"
            />
            <path
              d="M125.757089,11.9984412 L125.468165,11.1566641 L125.208133,11.1566641 L125.208133,11.9984412 L125,11.9984412 L125,10 L125.531835,10 C125.790797,10 125.957731,10.2439595 125.957731,10.5845674 C125.957731,10.8706157 125.837881,11.0537802 125.683253,11.1145752 L126,12 L125.757089,11.9984412 L125.757089,11.9984412 Z M125.568182,10.0985 L125,10.0985 L125,10.9015 L125.568182,10.9015 C125.826087,10.9015 126,10.7545 126,10.5025 C126,10.2505 125.826087,10.0985 125.568182,10.0985 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default PloneSVG;
