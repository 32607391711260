import React from 'react';

const PartySmileSVG = () => (
  <svg width="106px" height="90px" xmlns="http://www.w3.org/2000/svg">
    <g fill="#000" fillRule="nonzero">
      <path d="M22.414 51.586A2.012 2.012 0 0 0 21 51c-.526 0-1.042.214-1.414.586A2.014 2.014 0 0 0 19 53c0 .528.214 1.042.586 1.416.372.372.888.584 1.414.584.526 0 1.042-.212 1.414-.584A2.02 2.02 0 0 0 23 53c0-.526-.214-1.042-.586-1.414zM42.414 51.586A2.015 2.015 0 0 0 41 51c-.526 0-1.042.214-1.414.586A2.014 2.014 0 0 0 39 53c0 .528.214 1.042.586 1.416.372.372.888.584 1.414.584.526 0 1.042-.212 1.414-.584.372-.374.586-.89.586-1.416 0-.526-.214-1.042-.586-1.414zM38.457 63.075c-1.109-.313-2.248.383-2.544 1.553-.594 2.346-2.614 3.985-4.913 3.985-2.299 0-4.319-1.639-4.913-3.985-.296-1.17-1.435-1.867-2.544-1.553-1.11.313-1.768 1.515-1.472 2.685C23.15 70.023 26.82 73 31 73c4.177 0 7.849-2.977 8.928-7.24.296-1.17-.363-2.372-1.472-2.685zM53 0c-1.105 0-2 .965-2 2.156v13.688c0 1.19.895 2.156 2 2.156s2-.965 2-2.156V2.156C55 .966 54.105 0 53 0zM54.414 21.586A2.007 2.007 0 0 0 53 21c-.528 0-1.042.212-1.416.586A2.013 2.013 0 0 0 51 23c0 .526.212 1.042.584 1.414A2.02 2.02 0 0 0 53 25c.526 0 1.042-.214 1.414-.586.372-.372.586-.888.586-1.414 0-.526-.214-1.042-.586-1.414zM40.356 19.248l-5.607-5.605a2.196 2.196 0 1 0-3.105 3.109l5.607 5.605c.429.429.99.643 1.553.643a2.198 2.198 0 0 0 1.553-3.752zM74.378 13.62a2.122 2.122 0 0 0-3 .002l-5.757 5.759a2.12 2.12 0 1 0 3 2.997l5.758-5.759a2.12 2.12 0 0 0 0-2.999z" />
      <path d="M104.952 51.603c-4.375-16.24-21.218-25.912-37.545-21.56-5.604 1.493-10.555 4.463-14.422 8.575-5.786-6.13-13.793-9.6-22.33-9.6C13.753 29.018 0 42.696 0 59.508 0 76.323 13.752 90 30.656 90c8.544 0 16.564-3.482 22.352-9.626 5.731 6.044 13.813 9.615 22.34 9.615 2.619 0 5.28-.337 7.927-1.042 16.328-4.352 26.052-21.104 21.677-37.344zM30.656 85.88c-14.62 0-26.514-11.83-26.514-26.371 0-14.541 11.894-26.371 26.514-26.371 7.568 0 14.655 3.154 19.686 8.707a31.137 31.137 0 0 0-1.55 2.404c-4.094 7.053-5.182 15.27-3.062 23.137a30.269 30.269 0 0 0 4.623 9.775c-5.031 5.56-12.127 8.719-19.697 8.719zm51.547-.913C68.082 88.73 53.515 80.365 49.731 66.32c-1.832-6.804-.892-13.91 2.649-20.01a26.753 26.753 0 0 1 2.273-3.316c3.505-4.366 8.298-7.498 13.826-8.971 2.29-.61 4.59-.902 6.856-.902 11.704 0 22.445 7.78 25.615 19.548 3.784 14.046-4.626 28.534-18.747 32.298z" />
      <path d="M68.928 55.242c-.648-2.33-3.142-3.717-5.562-3.092-2.418.624-3.859 3.026-3.21 5.355.25.9 1.096 1.495 2.02 1.495.18 0 .362-.022.543-.07 1.118-.288 1.78-1.393 1.48-2.468a.34.34 0 0 1 .25-.418c.118-.03.211.002.27.034a.338.338 0 0 1 .164.207c.3 1.076 1.447 1.714 2.565 1.426 1.117-.288 1.78-1.394 1.48-2.47zM87.928 50.242a4.37 4.37 0 0 0-2.115-2.654 4.663 4.663 0 0 0-3.446-.437 4.49 4.49 0 0 0-2.757 2.037 4.202 4.202 0 0 0-.453 3.317c.25.901 1.096 1.495 2.02 1.495.18 0 .362-.022.544-.07 1.116-.288 1.78-1.393 1.48-2.468a.324.324 0 0 1 .035-.258.36.36 0 0 1 .483-.125.337.337 0 0 1 .165.207c.3 1.075 1.447 1.713 2.564 1.425 1.117-.288 1.78-1.393 1.48-2.469zM86.688 63.54a2.08 2.08 0 0 0-4.018 1.074 4.954 4.954 0 0 1-3.507 6.062 4.935 4.935 0 0 1-3.763-.495 4.917 4.917 0 0 1-2.31-3.005 2.08 2.08 0 0 0-4.019 1.074 9.044 9.044 0 0 0 4.25 5.527 9.08 9.08 0 0 0 6.92.909c4.856-1.299 7.749-6.3 6.447-11.147z" />
    </g>
  </svg>
);

export default PartySmileSVG;
