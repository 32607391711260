import React from 'react';

const LogoJoySVG = () => (
  <svg
    id="Livello_1"
    version="1.1"
    viewBox="0 0 120 120"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
  >
    <line className="st0" x1="60.7" x2="59" y1="36" y2="35.4" />
    <circle className="st1" cx="76.9" cy="29.2" r="5.4" />
    <path
      className="st1"
      d="M93.2,39.9c0,3-2.4,5.4-5.4,5.4c-3,0-5.4-2.4-5.4-5.4c0-3,2.4-5.4,5.4-5.4C90.8,34.6,93.2,37,93.2,39.9z"
    />
    <g>
      <path
        className="st1"
        d="M58.2,30.3v0.2l-0.4,0.4v5.8l2.7,1C61,33.1,63.1,29,66.1,26c3.6-3.6,8.5-5.8,14-5.8c5.5,0,10.4,2.2,14,5.8&#xA;&#x9;&#x9;c3.6,3.6,5.8,8.5,5.8,14c0,3.7-1,7.1-2.8,10.1l2.8,1.8v0.4l-0.8,0.6l0,3c3.6-4.3,5.8-9.8,5.8-15.9c0-13.7-11.1-24.8-24.8-24.8&#xA;&#x9;&#x9;c-10.1,0-18.8,6-22.6,14.7L58.2,30.3z"
      />
    </g>
    <polygon
      className="st2"
      points="102.3,68.1 113.2,70.7 111.7,72.3 99.5,69.4 99.5,67.4 "
    />
    <polygon
      className="st2"
      points="103.7,59.4 102.4,60.5 102.4,60.4 94.5,58.1 94.5,56.6 96.7,57.3 97.8,57.6 99.4,58.1 "
    />
    <polygon
      className="st2"
      points="94.5,51.2 94.5,51.2 94.5,50 100.1,51.8 100.1,52.2 99.3,52.8 "
    />
    <polygon
      className="st2"
      points="94.5,68.2 94.5,68.2 87.4,69.4 88.6,68.5 88.6,65.1 88.6,63.1 87.1,62.7 87.1,59.7 94.5,58.1 &#xA;&#x9;94.5,62.7 "
    />
    <polygon
      className="st2"
      points="94.5,51.2 94.5,56.6 90.2,57.6 90.2,52.2 "
    />
    <polygon className="st2" points="94.5,51.2 94.5,51.2 94.5,51.2 " />
    <polygon
      className="st2"
      points="91.7,86.4 91.7,92.5 90.1,92.7 90.1,86.5 "
    />
    <polygon
      className="st2"
      points="88.6,63.1 88.6,65.1 75.7,61.9 75.7,59.7 87.1,62.7 "
    />
    <polygon className="st2" points="72.8,70 72.8,75.7 70.6,76 70.6,70.3 " />
    <polygon
      className="st2"
      points="53.7,47.6 53.7,46.6 53.7,46.6 62.6,49.1 62.6,49.1 68.3,50.8 68.3,51.8 53.7,47.6 "
    />
    <polygon
      className="st2"
      points="63.5,38.6 63.5,39.1 62.6,39.9 62.6,39.9 53.7,36.9 53.7,35.1 58,36.7 "
    />
    <polygon
      className="st2"
      points="58.4,30.3 58.4,30.5 58,30.9 54.1,29.4 53.7,29.2 53.7,28.5 54.1,28.7 "
    />
    <polygon className="st2" points="53.7,46.6 53.7,46.6 53.7,46.6 " />
    <polygon
      className="st2"
      points="53.7,29.2 53.7,35.1 49.3,36.7 49.3,36.5 49.3,30.9 "
    />
    <polygon className="st2" points="52,47 52,47.1 50.1,47.6 50.1,47.6 " />
    <polygon
      className="st2"
      points="44.8,39.9 44.7,49.1 50.1,47.6 52,47 53.7,46.6 53.7,46.6 53.7,36.9 "
    />
    <polygon
      className="st2"
      points="49.1,76.1 49.1,81.5 47.3,81.7 47.2,76.4 "
    />
    <polygon
      className="st2"
      points="48.7,60.7 48.7,66.1 45.9,66.6 45.9,61.2 "
    />
    <polygon
      className="st2"
      points="45.6,93.5 45.6,98.4 42.9,98.6 42.9,93.7 "
    />
    <polygon
      className="st2"
      points="45.1,76.8 45.1,82.2 43.2,82.4 43.3,77.1 "
    />
    <polygon
      className="st2"
      points="40.3,63.1 40.3,61.3 40.3,61.3 28.5,64 28.5,65.5 28.4,69.5 40.3,67 40.3,67 "
    />
    <polygon className="st2" points="35.8,90.7 35.8,95 34.9,95.1 34.9,90.8 " />
    <polygon
      className="st2"
      points="35.8,82.3 35.8,86.8 34.9,86.9 34.9,82.4 "
    />
    <polygon
      className="st2"
      points="33.5,82.6 33.5,87.2 32.6,87.3 32.6,82.8 "
    />
    <polygon
      className="st2"
      points="31.3,91.1 31.3,95.4 30.4,95.5 30.4,91.2 "
    />
    <polygon className="st2" points="29,91.3 29,95.6 28.1,95.6 28.1,91.3 " />
    <polygon
      className="st2"
      points="21,55.3 24.5,56.2 28.7,57.1 28.7,57.6 28.1,58.4 19.3,56.6 19.2,56.6 19.2,55 19.3,55 "
    />
    <polygon
      className="st2"
      points="23,63 25,63.4 28,63.9 28.5,64 28.5,65.5 19.3,64 19.3,62.3 "
    />
    <polygon className="st2" points="26.9,83.4 26.9,87.8 26,87.9 26,83.6 " />
    <polygon
      className="st2"
      points="25,52 25,52.3 24.6,52.6 21,51.8 21,51.1 "
    />
    <polygon
      className="st2"
      points="24.1,70.4 24.1,72.8 19.3,72.2 19.3,69.7 "
    />
    <polygon className="st2" points="22,49.6 21,51.1 18.8,51.8 " />
    <polygon
      className="st2"
      points="21,51.8 21,55.3 19.3,55 19.2,55 19.2,54.8 19.3,52.4 "
    />
    <polygon
      className="st2"
      points="19.3,68.1 19.3,69.7 10.3,71.8 10.3,66.1 19.3,64 19.3,66.6 "
    />
    <polygon
      className="st2"
      points="19.3,61.5 19.3,62.3 15,63.4 15,63.4 15,57.9 19.2,56.6 19.2,61.5 "
    />
    <line className="st0" x1="60.7" x2="59" y1="37.7" y2="37" />
    <polygon className="st0" points="53.7,27.2 53.7,28.5 58.4,30.3 " />
    <polygon className="st2" points="53.7,27.2 53.7,28.5 49.6,30 " />
    <polygon className="st0" points="89.4,51.2 94.5,48.1 94.5,50 " />
    <path
      className="st2"
      d="M7.5,99.5c0.5,0.1,1,0.1,1.5,0.2c0.2-2.2,1.8-4,3.9-4.4c0.3-0.1,0.5-0.1,0.7-0.1c1.8,0,3.4,1.5,3.4,3.4v2.3&#xA;&#x9;c0.7,0.1,1.4,0.2,2.1,0.3V77.7v-5.5L5.9,74.7v3.6l1.6,1.3V99.5z"
    />
    <path
      className="st2"
      d="M27.6,102.4l12.9,0l-0.2-32.6h0l-16.1,3.1h0v5.4v23.7C25.3,102.1,26.5,102.2,27.6,102.4z"
    />
    <path
      className="st2"
      d="M40.3,61.3l0,1.8l0,4l0,2.7l0.2,32.6l6.7,0c2.8-0.8,4.9-1.5,6.5-2.2V55.3v-7.7v0L39,51.8v0.9v4.6l1.2,1.8&#xA;&#x9;L40.3,61.3z"
    />
    <path
      className="st2"
      d="M76,93.5l-0.3-26.6l0-4.9l-0.1,0L67,63.7v4.7v25.8C69.5,93.9,72.5,93.6,76,93.5z"
    />
    <path
      className="st2"
      d="M99.5,96.7V69.4h0h-0.1l-12,1.8h0v22.9C91.1,94.7,95.2,95.5,99.5,96.7z"
    />
    <path
      className="st2"
      d="M109.4,100.1v-6c0-1.1-0.7-2.1-1.8-2.4l-0.2-0.1c-0.8-0.3-1.6,0.3-1.6,1.2v5.9&#xA;&#x9;C106.9,99.2,108.1,99.6,109.4,100.1z"
    />
    <polygon
      className="st2"
      points="75.7,54.7 75.7,53 68.3,54.8 68.3,56.5 68.3,57.2 67,58.5 67,61.6 75.7,59.7 75.7,59.7 "
    />
    <g>
      <path
        className="st1"
        d="M75.7,53l4.9,1.5c1-1,1.7-2.3,1.7-3.9c0-3-2.4-5.4-5.4-5.4c-3,0-5.4,2.4-5.4,5.4c0,1.2,0.4,2.2,1,3.1L75.7,53&#xA;&#x9;&#x9;L75.7,53z"
      />
      <polygon className="st1" points="75.7,53.5 75.7,54.7 75.7,54.7 &#x9;" />
    </g>
    <polygon className="st2" points="88.6,57 87.1,57.9 75.7,54.7 75.7,53 " />
  </svg>
);

export default LogoJoySVG;
