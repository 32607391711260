// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/ploneconf-gatsby/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-default-js": () => import("/opt/ploneconf-gatsby/src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-pages-404-js": () => import("/opt/ploneconf-gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-form-js": () => import("/opt/ploneconf-gatsby/src/pages/contact-form.js" /* webpackChunkName: "component---src-pages-contact-form-js" */),
  "component---src-pages-index-js": () => import("/opt/ploneconf-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-schedule-js": () => import("/opt/ploneconf-gatsby/src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/ploneconf-gatsby/.cache/data.json")

