import React from 'react';

const CastelloLogoSVG = () => (
  <svg viewBox="0 0 606 496" version="1.1">
    <g
      id="grafica"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="homepage-large" transform="translate(-1090.000000, -1088.000000)">
        <g id="Group-2" transform="translate(-112.000000, 1058.000000)">
          <g
            id="castello"
            transform="translate(1160.000000, 30.000000)"
          >
            <path
              d="M439.998638,45 C457.118143,45 471,58.8789915 471,75.9972768 C471,93.1210085 457.118143,107 439.998638,107 C422.879134,107 409,93.1210085 409,75.9972768 C409,58.8789915 422.879134,45 439.998638,45"
              id="Fill-1"
              fill="#242424"
            />
            <path
              d="M535,139 C535,156.133407 521.134625,170 503.994554,170 C486.899416,170 473,156.126598 473,139 C473,121.866593 486.910309,108 503.994554,108 C521.141433,108 535,121.873402 535,139"
              id="Fill-3"
              fill="#242424"
            />
            <path
              d="M440.002042,170 C457.121923,170 471,183.882914 471,200.999319 C471,218.122532 457.121923,232 440.002042,232 C422.882162,232 409,218.122532 409,200.999319 C409,183.882914 422.882162,170 440.002042,170"
              id="Fill-5"
              fill="#242424"
            />
            <path
              d="M533.228288,221.13813 C513.161284,241.543577 485.5893,254.123327 454.990753,254.130052 C424.394847,254.123327 396.826826,241.535508 376.758501,221.13813 C356.717919,200.705786 344.363303,172.638715 344.355376,141.5 C344.363303,110.354561 356.717919,82.2874895 376.765107,61.8551456 C396.833432,41.4496987 424.405415,28.8686036 454.998679,28.8618794 C485.594585,28.8686036 513.166568,41.4496987 533.234893,61.8551456 C553.283402,82.2874895 565.636697,110.354561 565.643302,141.5 C565.630092,172.638715 553.276797,200.705786 533.228288,221.13813 M454.998679,0 C378.232812,0.00672426247 316.007926,63.3479319 316,141.5 C316.007926,219.643999 378.232812,282.985207 454.998679,283 C531.768509,282.985207 593.985468,219.650723 594,141.5 C593.985468,63.3479319 531.768509,0.00672426247 454.998679,0"
              id="Fill-7"
              fill="#242424"
            />
            <polygon
              id="Fill-11"
              fill="#131413"
              points="648 318.854741 638.957212 328 568 311.647047 568 300 584.621101 303.919784"
            />
            <path
              d="M636,349.489335 L636,495.829699 L623.123756,495.876266 L623.123756,495.829699 L623.123756,450.268886 C623.123756,444.078182 619.156084,438.584647 613.312785,436.712668 L611.985418,436.277602 C607.469485,434.838027 602.861739,438.260011 602.861739,443.072342 L602.861739,495.829699 L602.861739,495.922832 L568,496 L568,495.829699 L568,311 L636,327.080131 L636,349.489335 Z"
              id="Fill-13"
              fill="#333332"
            />
            <path
              d="M624,495.905099 L624,495.953237 L606,496 L606,495.905099 L606,441.36731 C606,436.3912 610.092445,432.855108 614.105652,434.343265 L615.28491,434.791637 C620.476209,436.728167 624,442.407095 624,448.80672 L624,495.905099 Z"
              id="Fill-15"
              fill="#131413"
            />
            <polygon
              id="Fill-17"
              fill="#131413"
              points="594 253.036826 586.422662 258 586.339286 257.922015 539 246.971839 539 240 552.132451 243.110478 558.577852 244.63898 568.012076 246.86823"
            />
            <polygon
              id="Fill-19"
              fill="#333332"
              points="583.920883 255.205744 584 255.306914 583.920883 255.373397 583.376453 302.228216 567.09047 298.041214 539.061684 303 539 302.580866 539 268.957667 539 241.066483 539 241"
            />
            <polygon
              id="Fill-21"
              fill="#131413"
              points="572 206.591221 572 208.898818 567.339974 212 539.064914 203.533438 539.033148 203.518558 539 197"
            />
            <polygon
              id="Fill-23"
              fill="#131413"
              points="573 211 540.570087 202.000582 511 207.981966 540.570087 193"
            />
            <path
              d="M522.379819,441.265383 L522.162903,406.893581 L513.061725,407.434586 L513.061725,441.976533 L522.379819,441.265383 Z M547.008493,363.816506 L547.05374,328.410545 L537.472152,329.723846 L537.472152,337.981161 L537.472152,364.667227 L547.008493,363.816506 Z M566,495.659711 L566,495.829856 L498,496 L498,495.659711 L498,321.188718 L498.122431,321.188718 L565.69126,311 L565.970723,311 L566,311 L566,495.659711 Z"
              id="Fill-25"
              fill="#131413"
            />
            <polygon
              id="Fill-27"
              fill="#333332"
              points="566 303 566 312.521018 565.970723 312.521018 565.691266 312.521018 498.12376 321 498 321 498 312.289823 498 312.264381 538.184661 306.795354"
            />
            <polygon
              id="Fill-29"
              fill="#333332"
              points="566.857282 220.702331 567 246 557.925825 243.743898 557.925825 228.050455 551.726408 226.60655 551.726408 242.195084 539.095146 239.046694 539.095146 238.940658 539 213 539.031262 213"
            />
            <polygon
              id="Fill-31"
              fill="#131413"
              points="559 221.516588 559 238 551 236.373223 551 220"
            />
            <polygon
              id="Fill-33"
              fill="#131413"
              points="538.939069 296.210562 539 296.586744 499.002775 303 505.796581 298.081095 505.796581 279.642983 505.796581 268.492426 497 266.213281 497 249.526362 538.939069 241 538.939069 266.032973"
            />
            <polygon
              id="Fill-35"
              fill="#333332"
              points="539 240.814032 539 240.877872 497 250 497 241.653662 514.58954 237.617887 539 232 539 232.129067"
            />
            <polygon
              id="Fill-37"
              fill="#131413"
              points="538.931529 205 539 233.060494 521 238 521 210.251886"
            />
            <polygon
              id="Fill-39"
              fill="#333332"
              points="546 205.238797 546 205.257599 518.262448 212 518.262448 211.600439 513 207.553118 513 205.057035 545.965199 197"
            />
            <polygon
              id="Fill-41"
              fill="#333332"
              points="506 285.514323 506 304.817674 499.157375 309.967407 499.157375 310 433.265435 295.418596 433 267"
            />
            <polygon
              id="Fill-43"
              fill="#131413"
              points="506 272.744935 506 285 433.122508 265.563831 433 252 497.15547 270.241457"
            />
            <polygon
              id="Fill-45"
              fill="#131413"
              points="506 238.039551 497.196579 243 433.075549 224.464655 433 215"
            />
            <path
              d="M472.751989,364.489766 L472.751989,332.759154 L461.700148,330.679613 L461.700148,362.956855 L472.751989,364.489766 Z M495,488.514687 L495,488.848666 L476.591142,488.879976 L476.591142,458.134341 C476.591142,453.990914 472.79466,450.33541 467.203478,449.090817 L465.948442,448.802499 C461.627356,447.846223 457.218417,450.122759 457.218417,453.338612 L457.218417,488.939988 L434.752029,489 L434.752029,488.514687 L433,293 L495,307.006243 L495,317.278706 L495,488.514687 Z"
              id="Fill-47"
              fill="#333332"
            />
            <polygon
              id="Fill-49"
              fill="#333332"
              points="495 253.048577 495 270 433.253752 253.140812 433 225 495 243.039793 495 245.124297"
            />
            <path
              d="M478,463.178207 L478,495.936058 L460,496 L460,458.068394 C460,454.640538 464.096528,452.214906 468.112594,453.2352 L469.278699,453.541011 C474.473698,454.867116 478,458.763418 478,463.178207"
              id="Fill-51"
              fill="#131413"
            />
            <polygon
              id="Fill-53"
              fill="#131413"
              points="478 328.029711 478 359 463 357.50382 463 326"
            />
            <path
              d="M413.240167,341.711209 L413.240167,310.151467 L399.941482,312.137152 L399.941482,343.485678 L413.240167,341.711209 Z M433,488.393734 L433,488.878747 L378,489 L378,488.393734 L378,301.628537 L431.018619,293 L433,488.393734 Z"
              id="Fill-55"
              fill="#131413"
            />
            <polygon
              id="Fill-57"
              fill="#131413"
              points="423.761299 267.111047 424 292.097767 379 300 379 275.986431 423.340558 267"
            />
            <polygon
              id="Fill-59"
              fill="#333332"
              points="423.887285 252.074044 424 262.138685 423.577015 262.040313 379 270 379 260.452606 423.577015 252"
            />
            <polygon
              id="Fill-61"
              fill="#131413"
              points="423.759423 225.025904 424 249.078737 423.688951 249.001024 379 258 379 243.327952 385.685117 237.144676 385.685117 233.659966 423.688951 225"
            />
            <polygon
              id="Fill-63"
              fill="#333332"
              points="432.922262 220 433 228.586108 432.922262 228.556632 391 238 391 229.029476"
            />
            <polygon
              id="Fill-65"
              fill="#333332"
              points="391 235.480845 391 239.632786 383.915044 247 311 228.690663 311 185 391 208.64889 391 213.54963 391 225.678023"
            />
            <polygon
              id="Fill-67"
              fill="#131413"
              points="391 206.73 391 213 311 186.686606 311 186.668688 311 180.034344 311.060522 180 359.859415 196.308869"
            />
            <path
              d="M348.625848,287.81144 L348.625848,258.692001 L333.5705,255.654285 L333.5705,284.635162 L348.625848,287.81144 Z M348.625848,370.088244 L348.625848,342.207874 L338.518473,340.798267 L338.518473,368.522755 L348.625848,370.088244 Z M374,490.209927 L374,490.829462 L301,491 L301,225 L374,243.178332 L374,260.535948 L374,272.561573 L374,299.357425 L374,490.209927 Z"
              id="Fill-69"
              fill="#333332"
            />
            <polygon
              id="Fill-71"
              fill="#131413"
              points="365 134.478188 365 137.411915 359.107016 141.886952 358.970762 142 303 124.392122 303 114 330.47591 123.075529"
            />
            <path
              d="M343.398651,192.016056 L343.398651,164.006407 L334.113539,160.770436 L334.113539,189.10027 L343.398651,192.016056 Z M355,144.759861 L355,196 L310.055742,181.667773 L310,181.652026 L310,128 L354.820916,144.759861 L354.931213,144.652258 L355,144.759861 Z"
              id="Fill-73"
              fill="#333332"
            />
            <polygon
              id="Fill-75"
              fill="#131413"
              points="363 339.589584 363 371 348 369.236297 348 338"
            />
            <polygon
              id="Fill-77"
              fill="#131413"
              points="351 263.117335 351 293 336 289.740471 336 260"
            />
            <polygon
              id="Fill-79"
              fill="#131413"
              points="345 161.417664 345 191 330 187.920499 330 158"
            />
            <polygon
              id="Fill-81"
              fill="#131413"
              points="336 84.3365356 336 85.7701297 333.550069 88 310.659256 78.7715163 308 77.7004061 308 73 310.659256 74.0711102"
            />
            <polygon
              id="Fill-83"
              fill="#131413"
              points="328 88 306.014948 78.6206168 303.707838 77.6419576 283 86.4688937 303.707838 70"
            />
            <polygon
              id="Fill-85"
              fill="#333332"
              points="336 122.985077 336 123 308 114.069533 308 80 310.914246 80.9794618 336 89.4052749"
            />
            <path
              d="M262.241561,376.982696 L262.241561,346.543296 L252.378815,348.091355 L252.15399,378.082177 L262.241561,376.982696 Z M264.931752,468.628345 L264.931752,440.912358 L250.436327,442.151604 L250.436327,469.602704 L264.931752,468.628345 Z M281.757647,286.314068 L281.757647,255.686983 L266.75476,258.723203 L266.75476,288.837817 L281.757647,286.314068 Z M284.402872,373.312877 L284.402872,342.889449 L273.672944,344.545327 L274.3153,374.397716 L284.402872,373.312877 Z M309,225.062561 L309,490.814978 L237.345095,491 L236.344303,306.891411 L236.254373,291.424128 L236.120763,268.988581 L236.075798,259.016043 L236,245.918103 L308.761043,225 L309,225.062561 Z"
              id="Fill-87"
              fill="#131413"
            />
            <polygon
              id="Fill-89"
              fill="#131413"
              points="303 185.015424 303 226.862017 302.758838 226.801609 229.720294 247 223 237.342303 223 212.360048 223 207.605747 303 185"
            />
            <polygon
              id="Fill-91"
              fill="#131413"
              points="310 181.652026 310 181.697954 301.481731 184.399846 301.481731 156.222231 292.160973 159.458201 292.160973 187.375994 265 196 265.08441 144.636511 310 128"
            />
            <polygon
              id="Fill-93"
              fill="#131413"
              points="311 80 311 114.181407 283.29882 123 283 122.255476 283 89.4365346"
            />
            <path
              d="M155.291409,413.257688 L155.291409,388.520489 L149.96427,389.204442 L149.96427,413.880193 L155.291409,413.257688 Z M167.960643,457.248439 L167.960643,432.930695 L162.616748,433.395569 L162.616748,457.63717 L167.960643,457.248439 Z M180.629876,411.549141 L180.906357,385.631054 L175.562462,386.315007 L175.562462,412.000657 L180.629876,411.549141 Z M181.555668,456.330712 L181.555668,431.935489 L176.211773,432.338915 L176.211773,456.735474 L181.555668,456.330712 Z M194.224901,409.71636 L194.224901,383.983955 L188.881007,384.730693 L188.881007,410.352223 L194.224901,409.71636 Z M208.111767,407.77404 L208.111767,382.057665 L202.767872,382.693528 L202.767872,408.332424 L208.111767,407.77404 Z M208.111767,454.388392 L208.111767,429.573713 L202.767872,430.025229 L202.767872,454.683614 L208.111767,454.388392 Z M234.91223,311 L236,495.767563 L139,496 L139,495.985306 L139,359.246756 L139,328.403404 L139.261121,328.403404 L234.683226,311 L234.91223,311 Z"
              id="Fill-95"
              fill="#131413"
            />
            <polygon
              id="Fill-97"
              fill="#131413"
              points="235.859588 270 236 290.313904 235.812334 290.313904 168 303 168.078307 282.518571 168.189016 282.518571 235.732677 270"
            />
            <polygon
              id="Fill-99"
              fill="#131413"
              points="168 236.300831 168 239.090503 164.583689 243 113.175688 233.48273 113 233.525991 113 225.028428 113.063138 225 122.962067 227.041887 143.829153 231.32713"
            />
            <polygon
              id="Fill-101"
              fill="#131413"
              points="168 274.451774 167.95079 283 113 274.482561 113 265 135.633826 268.892615 147.245999 270.885774 165.464978 274.015394"
            />
            <polygon
              id="Fill-103"
              fill="#131413"
              points="150 253.468447 150 270 135 268.365831 135 252"
            />
            <polygon
              id="Fill-105"
              fill="#131413"
              points="140 207.636807 140 209.231023 138.14289 211 122 206.745403 122 203"
            />
            <polygon
              id="Fill-107"
              fill="#131413"
              points="140 212 118.313671 206.495522 107 211.131343 123.728297 197"
            />
            <path
              d="M128.082314,383.431494 L128.082314,364.403953 L122.515741,363.997018 L122.515741,382.852085 L128.082314,383.431494 Z M128.082314,429.497253 L128.082314,402.647681 L122.515741,402.161247 L122.515741,429.119963 L128.082314,429.497253 Z M141,495.9205 L141,495.936669 L113,496 L113,495.700863 L113,355 L141,357.994065 L141,495.9205 Z"
              id="Fill-109"
              fill="#333332"
            />
            <polygon
              id="Fill-111"
              fill="#131413"
              points="141 316.927546 141 331 113 327.501709 113 313"
            />
            <polygon
              id="Fill-113"
              fill="#131413"
              points="128 400.496894 128 428 120 427.613527 120 400"
            />
            <polygon
              id="Fill-115"
              fill="#131413"
              points="128 361.376898 128 379 120 378.463357 120 361"
            />
            <polygon
              id="Fill-117"
              fill="#131413"
              points="130 205 130 223 115.095068 221.027086 115 221.054571 115 220.053177 115.192202 208.099781"
            />
            <polygon
              id="Fill-119"
              fill="#333332"
              points="121 203 121 207.275664 108.984908 211 106 208.829146 106 207.45944"
            />
            <path
              d="M115,495.528964 L115,495.827735 L102.653951,495.857343 L102.653951,495.655471 L102.653951,474.317527 C102.653951,463.218567 93.479564,454.640323 82.7983651,454.640323 C81.3719346,454.640323 79.9141689,454.796438 78.4713896,455.110013 C65.1294278,458.021018 55.6035422,469.700026 55.6035422,483.209346 L55.6035422,495.9677 L47,496 L47,365.816339 L115,355 L115,495.528964 Z"
              id="Fill-121"
              fill="#131413"
            />
            <polygon
              id="Fill-123"
              fill="#131413"
              points="115 323 115 355.016304 50.6667844 366 42 358.49714 42 337.401665"
            />
            <polygon
              id="Fill-125"
              fill="#333332"
              points="115 311 115 325.046344 42 339 42 328.019011 66.0940215 322.396765"
            />
            <polygon
              id="Fill-127"
              fill="#131413"
              points="115 302.058418 115 311.233945 70 323 70 290.211708 115 278 115 293.105717"
            />
            <polygon
              id="Fill-129"
              fill="#333332"
              points="115 265.091683 115 273.011057 59.0089016 283 53 279.267843 53 276.887446 88.2063787 270.137586 88.2063787 270.124169 115 265"
            />
            <polygon
              id="Fill-131"
              fill="#131413"
              points="115 261.772036 115 265.776216 97.0464733 271 97 270.81421 97 244.284954 114.954515 238 114.954515 261.732143"
            />
            <polygon
              id="Fill-133"
              fill="#333332"
              points="115 225 115 233.324588 88.0435917 240 82 235.958185 82 233.380287"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CastelloLogoSVG;
