import React from 'react';

const CastelloLogoBluSVG = () => (
  <svg
    height="387px"
    width="608px"
    version="1.1"
    viewBox="0 0 608 387"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <path
        id="path-1"
        d="M0.162848388,164.606808 L0.162848388,164.75074 L31.8459069,164.678774 L31.8459069,164.606808 L31.8459069,117.64907 C31.8459069,115.849923 32.5877718,114.266673 33.7458048,113.133211 L33.7458048,113.133211 C33.8887495,112.989279 34.0335036,112.845347 34.2162557,112.719407 L34.2162557,112.719407 C34.3610098,112.593467 34.5238582,112.467526 34.7048008,112.359578 L34.7048008,112.359578 C35.0485919,112.125688 35.3923829,111.945774 35.7723625,111.783851 L35.7723625,111.783851 C37.0932438,111.242307 38.6131621,111.118166 40.1149861,111.603936 L40.1149861,111.603936 L41.3273019,111.981757 C46.6289216,113.636972 50.2296804,118.530652 50.2296804,124.054033 L50.2296804,124.054033 L50.2296804,164.606808 L50.2296804,164.642791 L61.9366701,164.606808 L61.9366701,34.3125788 L61.9565738,34.3125788 L61.9565738,14.3600381 L61.9366701,14.3600381 L0.162848388,0.0568191098 L0.162848388,164.606808 Z"
      />
      <linearGradient
        id="linearGradient-3"
        x1="-2.8696085e-05%"
        x2="99.9970475%"
        y1="49.9999993%"
        y2="49.9999993%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-4"
        points="0.353008696 51.4774384 0.408 51.8235359 24.8170435 47.706798 39.0083478 51.185988 39.4873043 12.2955665 39.5600348 12.2409195 39.4873043 12.1680569 0.370747826 0.382528736"
      />
      <linearGradient
        id="linearGradient-6"
        x1="0.00457967605%"
        x2="99.9955325%"
        y1="50.0000064%"
        y2="50.0000064%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-7"
        points="36.295 4.45454545 0.627941176 10.3818182 0.627941176 10.4 0.627941176 19.8363636 0.733794118 19.8363636 60.7147353 10.6727273 60.9820588 10.6727273 60.9820588 0.345454545"
      />
      <linearGradient
        id="linearGradient-9"
        x1="1.81331748e-05%"
        x2="100.000016%"
        y1="50.0000289%"
        y2="50.0000289%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-10"
        points="16.4317045 4.68712121 0.651477273 8.03083333 0.651477273 14.9325758 38.3629631 7.38787879 38.3629631 7.33560606 38.3629631 0.139393939 38.3629631 0.0522727273"
      />
      <linearGradient
        id="linearGradient-12"
        x1="2.87921682e-06%"
        x2="100.004803%"
        y1="50.0000363%"
        y2="50.0000363%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-13"
        points="0.267857143 0.100153128 0.355357143 28.2896209 0.355357143 28.3826555 11.3017857 31.8249337 11.3017857 14.8926461 16.6428571 16.4556265 16.6428571 33.4995556 24.5 35.9370607 24.3571429 8.47326237 0.303571429 0.100153128"
      />
      <linearGradient
        id="linearGradient-15"
        x1="-4.49520999e-05%"
        x2="99.9999528%"
        y1="49.9999714%"
        y2="49.9999714%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-16"
        points="0.65158371 6.70738095 0.65158371 8.78531746 4.82986425 12.1178571 4.82986425 12.4511111 26.949095 6.88380952 26.949095 6.86420635 26.9307692 0.0815079365"
      />
      <linearGradient
        id="linearGradient-18"
        x1="2.16027874e-05%"
        x2="100.000024%"
        y1="50.000038%"
        y2="50.000038%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-19"
        points="0.649693694 24.8026262 59.4088088 37.4973113 59.4088088 37.4793581 65.5124324 32.9910661 65.5124324 16.204854 0.413133133 0.0649559229"
      />
      <linearGradient
        id="linearGradient-21"
        x1="-2.02496532e-05%"
        x2="99.9999775%"
        y1="49.9999914%"
        y2="49.9999914%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <path
        id="path-22"
        d="M2.27608783,178.487084 L2.27608783,178.920307 L23.3135576,178.866154 L23.3135576,146.446629 C23.3135576,145.52603 23.7253928,144.695686 24.4189162,144.009749 L24.4189162,144.009749 C24.5258864,143.901444 24.6506849,143.775087 24.7754835,143.666781 L24.7754835,143.666781 C25.0411261,143.450169 25.3281628,143.251609 25.6508562,143.08915 L25.6508562,143.08915 C27.200141,142.204653 29.3573731,141.843634 31.4789484,142.312959 L31.4789484,142.312959 L32.6556205,142.583723 C37.8775483,143.702883 41.4271757,147.042311 41.4271757,150.814961 L41.4271757,150.814961 L41.4271757,178.830052 L58.6493755,178.79395 L58.6493755,178.487084 L58.6493755,22.4906836 L58.6493755,13.1222351 L0.63766116,0.358401006 L2.27608783,178.487084 Z M27.5014404,64.0981474 L27.5014404,34.6931329 L37.8454573,36.5884837 L37.8454573,65.5061223 L27.5014404,64.0981474 Z"
      />
      <linearGradient
        id="linearGradient-24"
        x1="-0.00308061096%"
        x2="99.9999954%"
        y1="50.0005061%"
        y2="50.0005061%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-25"
        points="0.3215621 25.011 57.6603671 39.8863333 57.6603671 24.9403333 57.6603671 17.9425667 57.6603671 16.107 0.0866410585 0.171666667"
      />
      <linearGradient
        id="linearGradient-27"
        x1="0%"
        x2="100.000002%"
        y1="50.000004%"
        y2="50.000004%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-28"
        points="0.4482 9.76903846 0.4482 20.5820192 44.028 11.5681731 44.424 11.6772115 44.334 0.300865385 44.028 0.228173077"
      />
      <linearGradient
        id="linearGradient-30"
        x1="0.00409316033%"
        x2="100.000001%"
        y1="49.9999839%"
        y2="49.9999839%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-31"
        points="0.0501576044 8.52906579 0.0501576044 16.9611107 37.8330575 8.10649917 37.9064224 8.12403306 37.8330575 0.058446281"
      />
      <linearGradient
        id="linearGradient-33"
        x1="2.90697671e-06%"
        x2="100%"
        y1="50.0000446%"
        y2="50.0000446%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-34"
        points="0.102229199 39.9921603 67.2458446 56.4514266 73.7479121 49.8347283 73.7479121 46.0773234 73.7479121 37.2630014 73.7479121 26.3555299 73.7479121 21.9566168 0.102229199 0.695203804"
      />
      <linearGradient
        id="linearGradient-36"
        x1="1.63814181e-05%"
        x2="100.00002%"
        y1="49.999978%"
        y2="49.999978%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <path
        id="path-37"
        d="M0.114578755,238.494841 L67.4533223,238.330076 L67.4533223,237.786894 L67.4533223,67.0105303 L67.4533223,43.0381061 L67.4533223,32.265 L67.4533223,16.7481061 L0.114578755,0.488863636 L0.114578755,238.494841 Z M30.1478498,53.8474242 L30.1478498,27.9195455 L44.0331136,30.6173485 L44.0331136,56.6900758 L30.1478498,53.8474242 Z M34.7148718,128.915152 L34.7148718,104.091742 L44.0331136,105.359167 L44.0331136,130.309318 L34.7148718,128.915152 Z"
      />
      <linearGradient
        id="linearGradient-39"
        x1="1.79677653e-05%"
        x2="100.002702%"
        y1="49.9996217%"
        y2="49.9996217%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <path
        id="path-40"
        d="M0.113736264,48.8929825 L0.167513736,48.9111111 L44.8924451,61.945614 L44.8924451,15.3368421 L44.8207418,15.2643275 L44.7311126,15.3368421 L0.113736264,0.0906432749 L0.113736264,48.8929825 Z M24.098489,55.6730994 L24.098489,29.894152 L33.3482143,32.832807 L33.3482143,58.3380117 L24.098489,55.6730994 Z"
      />
      <linearGradient
        id="linearGradient-42"
        x1="2.68214572e-05%"
        x2="100.000025%"
        y1="49.9999839%"
        y2="49.9999839%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-43"
        points="0.103265306 29.7686267 22.3099413 37.5839845 22.3099413 8.23137331 2.3968699 0.866208897 0.103265306 0.00183752418"
      />
      <linearGradient
        id="linearGradient-45"
        x1="5.48775493e-05%"
        x2="100.008246%"
        y1="49.9999736%"
        y2="49.9999736%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-46"
        points="28.0342052 7.6224 0.0382293763 17.23376 0.0382293763 19.696 4.89134809 23.56976 49.9275654 8.872 49.9275654 0.1248"
      />
      <linearGradient
        id="linearGradient-48"
        x1="1.34301271e-05%"
        x2="100.000011%"
        y1="49.996242%"
        y2="49.996242%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-49"
        points="3.68171429 8.5190625 0.225657143 11.0053125 2.29822857 12.8334375 23.9291429 4.47890625 23.9291429 0.656296875"
      />
      <linearGradient
        id="linearGradient-51"
        x1="0.00748673492%"
        x2="100.000014%"
        y1="50.0075424%"
        y2="50.0075424%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-52"
        points="65.3907317 2.58272279 65.3907317 2.72967255 56.0956098 5.43171127 56.0956098 5.31966208 28.9321951 13.2733179 0.198536585 21.7229291 0.198536585 26.7375897 73.9278049 5.46661184 73.9278049 0.0845768541"
      />
      <linearGradient
        id="linearGradient-54"
        x1="1.46878827e-06%"
        x2="99.9999978%"
        y1="50.0000296%"
        y2="50.0000296%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-55"
        points="81.2438915 0.161303657 21.6913474 12.7230525 0.0702453724 17.2903021 0.0702453724 29.5783784 0.286276367 29.5783784 81.2978993 14.0461049 81.4977279 14.0461049 81.4239173 0.161303657"
      />
      <linearGradient
        id="linearGradient-57"
        x1="-4.64283343e-06%"
        x2="99.9977834%"
        y1="50.0030916%"
        y2="50.0030916%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-58"
        points="58.9111662 0.11 0.207600767 13.9883333 0.172012064 21.6883333 0.260983821 21.7066667 58.8933718 9.16666667 59.0001379 9.16666667 58.9663287 0.11"
      />
      <linearGradient
        id="linearGradient-60"
        x1="9.3768905e-06%"
        x2="100.000014%"
        y1="49.9999737%"
        y2="49.9999737%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-61"
        points="0 388 606.729382 388 606.729382 0.378445964 0 0.378445964"
      />
      <polygon
        id="path-63"
        points="0.648275862 13.6167553 0.648275862 21.4786579 0.648275862 29.5575789 25.2827586 32.9861053 46.9099617 28.3904211 47 7.98339474 0.648275862 0.360394737"
      />
      <linearGradient
        id="linearGradient-65"
        x1="5.43900542e-06%"
        x2="100.000005%"
        y1="50.0000067%"
        y2="50.0000067%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-66"
        points="0.592850275 0.0868400315 0.592850275 25.2326438 0.64842033 25.2674921 0.64842033 29.5061466 0.64842033 29.6161939 19.6480014 33.1193656 19.6480014 15.1266351 29.4014423 16.7406619 29.4014423 34.9168046 29.4014423 34.9351458 44.7100962 37.7596927 44.728022 37.612963 44.9449245 9.34915288 0.738049451 0.0318163909"
      />
      <linearGradient
        id="linearGradient-68"
        x1="0.0040368604%"
        x2="99.9959526%"
        y1="50.0000102%"
        y2="50.0000102%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-69"
        points="0.183241107 18.2723626 18.2249802 22.4685989 18.4412648 4.18107143 0.183241107 0.113104396"
      />
      <linearGradient
        id="linearGradient-71"
        x1="1.38203356e-05%"
        x2="100.000015%"
        y1="50.0000049%"
        y2="50.0000049%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-72"
        points="0.630645161 28.2988304 24.9016129 30.9637427 24.9016129 3.39005848 0.630645161 0.344444444"
      />
      <linearGradient
        id="linearGradient-74"
        x1="1.02339181e-05%"
        x2="100.000012%"
        y1="49.9999947%"
        y2="49.9999947%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <path
        id="path-75"
        d="M0.630645161,124.673913 L0.630645161,124.927536 L24.9016129,124.873188 L24.9016129,2.77173913 L0.630645161,0.108695652 L0.630645161,124.673913 Z M8.88064516,24.7644928 L8.88064516,8.07971014 L13.7241935,8.42391304 L13.7241935,25.2898551 L8.88064516,24.7644928 Z M8.88064516,65.7246377 L8.88064516,41.8641304 L13.7241935,42.3007246 L13.7241935,66.0507246 L8.88064516,65.7246377 Z"
      />
      <linearGradient
        id="linearGradient-77"
        x1="1.02339181e-05%"
        x2="100.000012%"
        y1="49.9999975%"
        y2="49.9999975%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-78"
        points="0.41755102 4.176 0.41755102 5.408 2.52057143 7.344 10.9955102 4.01955556 10.9955102 0.186666667"
      />
      <linearGradient
        id="linearGradient-80"
        x1="-3.05602716e-05%"
        x2="99.9999677%"
        y1="50.0001339%"
        y2="50.0001339%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-81"
        points="22.4162841 10.3612847 0.122460368 15.2880339 0.122460368 24.9525174 67.6559036 12.6930556 67.6559036 0.362934028"
      />
      <linearGradient
        id="linearGradient-83"
        x1="1.34084205e-06%"
        x2="100.000004%"
        y1="50.0000194%"
        y2="50.0000194%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-84"
        points="28.3214286 5.73958333 0.267857143 13.2020833 0.267857143 15.8270833 5.05357143 19.9333333 49.6607143 8.92708333 49.6607143 0.170833333 49.6607143 0.0583333333"
      />
      <linearGradient
        id="linearGradient-86"
        x1="-1.4461316e-06%"
        x2="99.9999978%"
        y1="50.0000113%"
        y2="50.0000113%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
      <polygon
        id="path-87"
        points="0.27 8.61403509 0.27 11.0701754 5.0922 14.9315789 26.6022 8.56140351 26.6022 0.614035088"
      />
      <linearGradient
        id="linearGradient-89"
        x1="1.02536059e-05%"
        x2="100.006847%"
        y1="49.9937839%"
        y2="49.9937839%"
      >
        <stop offset="0%" stopColor="#447BA5" />
        <stop offset="100%" stopColor="#5EBCD3" />
      </linearGradient>
    </defs>
    <g
      id="grafica"
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
    >
      <g id="Training" transform="translate(-657.000000, -1675.000000)">
        <g id="testo-CFS" transform="translate(274.000000, 547.000000)">
          <g id="Castello" transform="translate(383.000000, 1128.000000)">
            <polygon
              id="Fill-1"
              fill="#13007C"
              points="553.736663 379.034261 535.34936 379.070236 503.660218 379.137045 442.779421 379.291221 425.298814 379.325482 406.909701 379.359743 385.556178 379.412848 339.441327 379.513919 271.954856 379.669807 205.499956 379.82227 122.702796 380.02955 97.9450885 380.079229 87.1588356 380.09636 46.0045755 380.199143 38.4596278 380.216274 0.561151079 381.569593 0.561151079 387 607.561151 385.937901 607.561151 380.695931 565.445901 379"
            />
            <polygon
              id="Fill-2"
              fill="#EDF1F1"
              points="572.561151 220.164343 564.761229 228 503.561151 214.001467 503.561151 204 517.902538 207.363169"
            />
            <path
              id="Fill-3"
              d="M553.561151,378.927195 L553.561151,378.963598 L534.561151,379 L534.561151,378.927195 L534.561151,331.4222 C534.561151,329.602085 535.327883,328.000384 536.526604,326.853712 C536.67434,326.708102 536.844517,326.562493 537.010954,326.435085 C537.160561,326.307677 537.328868,326.180269 537.515875,326.071062 C537.87119,325.834447 538.226505,325.652436 538.619222,325.488626 C539.984379,324.942591 541.555246,324.815183 543.107411,325.306614 L544.360364,325.688838 C549.839694,327.363344 553.561151,332.314056 553.561151,337.901808 L553.561151,378.927195 Z"
              fill="#13007C"
            />
            <g id="Group-7" transform="translate(502.561151, 214.000000)">
              <mask id="mask-2" fill="white">
                <path d="M0.162848388,164.606808 L0.162848388,164.75074 L31.8459069,164.678774 L31.8459069,164.606808 L31.8459069,117.64907 C31.8459069,115.849923 32.5877718,114.266673 33.7458048,113.133211 L33.7458048,113.133211 C33.8887495,112.989279 34.0335036,112.845347 34.2162557,112.719407 L34.2162557,112.719407 C34.3610098,112.593467 34.5238582,112.467526 34.7048008,112.359578 L34.7048008,112.359578 C35.0485919,112.125688 35.3923829,111.945774 35.7723625,111.783851 L35.7723625,111.783851 C37.0932438,111.242307 38.6131621,111.118166 40.1149861,111.603936 L40.1149861,111.603936 L41.3273019,111.981757 C46.6289216,113.636972 50.2296804,118.530652 50.2296804,124.054033 L50.2296804,124.054033 L50.2296804,164.606808 L50.2296804,164.642791 L61.9366701,164.606808 L61.9366701,34.3125788 L61.9565738,34.3125788 L61.9565738,14.3600381 L61.9366701,14.3600381 L0.162848388,0.0568191098 L0.162848388,164.606808 Z" />
              </mask>
              <g id="Clip-6" />
              <path
                id="Fill-5"
                d="M0.162848388,164.606808 L0.162848388,164.75074 L31.8459069,164.678774 L31.8459069,164.606808 L31.8459069,117.64907 C31.8459069,115.849923 32.5877718,114.266673 33.7458048,113.133211 L33.7458048,113.133211 C33.8887495,112.989279 34.0335036,112.845347 34.2162557,112.719407 L34.2162557,112.719407 C34.3610098,112.593467 34.5238582,112.467526 34.7048008,112.359578 L34.7048008,112.359578 C35.0485919,112.125688 35.3923829,111.945774 35.7723625,111.783851 L35.7723625,111.783851 C37.0932438,111.242307 38.6131621,111.118166 40.1149861,111.603936 L40.1149861,111.603936 L41.3273019,111.981757 C46.6289216,113.636972 50.2296804,118.530652 50.2296804,124.054033 L50.2296804,124.054033 L50.2296804,164.606808 L50.2296804,164.642791 L61.9366701,164.606808 L61.9366701,34.3125788 L61.9565738,34.3125788 L61.9565738,14.3600381 L61.9366701,14.3600381 L0.162848388,0.0568191098 L0.162848388,164.606808 Z"
                fill="url(#linearGradient-3)"
              />
            </g>
            <g id="Group-10" transform="translate(478.561151, 156.000000)">
              <mask id="mask-5" fill="white">
                <polygon points="0.353008696 51.4774384 0.408 51.8235359 24.8170435 47.706798 39.0083478 51.185988 39.4873043 12.2955665 39.5600348 12.2409195 39.4873043 12.1680569 0.370747826 0.382528736" />
              </mask>
              <g id="Clip-9" />
              <polygon
                id="Fill-8"
                fill="url(#linearGradient-6)"
                points="0.353008696 51.4774384 0.408 51.8235359 24.8170435 47.706798 39.0083478 51.185988 39.4873043 12.2955665 39.5600348 12.2409195 39.4873043 12.1680569 0.370747826 0.382528736"
              />
            </g>
            <g
              id="Group-15"
              fill="#EDF1F1"
              transform="translate(451.561151, 106.000000)"
            >
              <polygon
                id="Fill-11"
                points="54.7656029 18.6180686 54.7656029 20.7792114 50.7527685 23.7020686 26.5011343 15.73064 26.4651286 15.7129257 26.448926 9.58378286"
              />
              <polygon
                id="Fill-13"
                points="54.7656029 18.6180686 26.448926 9.58378286 0.632844402 15.5712114 26.448926 0.584925714"
              />
            </g>
            <g id="Group-18" transform="translate(441.561151, 204.000000)">
              <mask id="mask-8" fill="white">
                <polygon points="36.295 4.45454545 0.627941176 10.3818182 0.627941176 10.4 0.627941176 19.8363636 0.733794118 19.8363636 60.7147353 10.6727273 60.9820588 10.6727273 60.9820588 0.345454545" />
              </mask>
              <g id="Clip-17" />
              <polygon
                id="Fill-16"
                fill="url(#linearGradient-9)"
                points="36.295 4.45454545 0.627941176 10.3818182 0.627941176 10.4 0.627941176 19.8363636 0.733794118 19.8363636 60.7147353 10.6727273 60.9820588 10.6727273 60.9820588 0.345454545"
              />
            </g>
            <g id="Group-29" transform="translate(440.561151, 136.000000)">
              <path
                id="Fill-19"
                d="M23.983966,194.947688 L23.7712851,164.190173 L15.7287319,164.677814 L15.7287319,195.579816 L23.983966,194.947688 Z M45.7363915,125.66652 L45.8060936,93.9879041 L45.7882213,93.9879041 L37.3167319,95.1618551 L37.3167319,102.530655 L37.3167319,126.407012 L45.7363915,125.66652 Z M62.5346043,243.603441 L62.5346043,243.747928 L2.4138383,243.910475 L2.4138383,243.603441 L2.4138383,87.5221434 L2.51928511,87.5221434 L62.2683064,78.4195081 L62.5346043,78.4195081 L62.5346043,243.603441 Z"
                fill="#EDF1F1"
              />
              <polygon
                id="Fill-21"
                fill="#EDF1F1"
                points="62.0533021 21.1124588 62.0533021 21.1305196 83.6949191 27.7046451 77.3877702 32.9964549 77.3162809 32.9242118 37.905983 21.2388843 37.8881106 21.2388843 37.8881106 13.7797804 48.8438553 17.1210255 54.1894723 18.7464961"
              />
              <polygon
                id="Fill-23"
                fill="#13007C"
                points="48.8445702 17.1212061 48.8445702 0.685892373 54.1884 2.20299825 54.1884 18.7466767"
              />
              <polygon
                id="Fill-25"
                fill="#13007C"
                points="45.7885787 93.9879041 45.7367489 125.66652 37.3170894 126.407012 37.3170894 102.530655 37.3170894 95.1618551"
              />
              <polygon
                id="Fill-27"
                fill="#EDF1F1"
                points="37.8890043 21.2932473 37.8890043 21.2390649 37.9068766 21.2390649 37.8890043 71.8995649 37.9426213 72.2427198 2.41240851 78.1305355 8.45325957 73.6153394 8.45325957 56.7285061 8.45325957 46.4880414 0.643046809 44.3929904 0.643046809 29.1135669"
              />
            </g>
            <g id="Group-32" transform="translate(438.561151, 150.000000)">
              <mask id="mask-11" fill="white">
                <polygon points="16.4317045 4.68712121 0.651477273 8.03083333 0.651477273 14.9325758 38.3629631 7.38787879 38.3629631 7.33560606 38.3629631 0.139393939 38.3629631 0.0522727273" />
              </mask>
              <g id="Clip-31" />
              <polygon
                id="Fill-30"
                fill="url(#linearGradient-12)"
                points="16.4317045 4.68712121 0.651477273 8.03083333 0.651477273 14.9325758 38.3629631 7.38787879 38.3629631 7.33560606 38.3629631 0.139393939 38.3629631 0.0522727273"
              />
            </g>
            <polygon
              id="Fill-33"
              fill="#EDF1F1"
              points="478.470392 122 478.561151 149.220663 456.561151 154 456.561151 127.102751"
            />
            <g id="Group-37" transform="translate(477.561151, 121.000000)">
              <mask id="mask-14" fill="white">
                <polygon points="0.267857143 0.100153128 0.355357143 28.2896209 0.355357143 28.3826555 11.3017857 31.8249337 11.3017857 14.8926461 16.6428571 16.4556265 16.6428571 33.4995556 24.5 35.9370607 24.3571429 8.47326237 0.303571429 0.100153128" />
              </mask>
              <g id="Clip-36" />
              <polygon
                id="Fill-35"
                fill="url(#linearGradient-15)"
                points="0.267857143 0.100153128 0.355357143 28.2896209 0.355357143 28.3826555 11.3017857 31.8249337 11.3017857 14.8926461 16.6428571 16.4556265 16.6428571 33.4995556 24.5 35.9370607 24.3571429 8.47326237 0.303571429 0.100153128"
              />
            </g>
            <g id="Group-40" transform="translate(451.561151, 114.000000)">
              <mask id="mask-17" fill="white">
                <polygon points="0.65158371 6.70738095 0.65158371 8.78531746 4.82986425 12.1178571 4.82986425 12.4511111 26.949095 6.88380952 26.949095 6.86420635 26.9307692 0.0815079365" />
              </mask>
              <g id="Clip-39" />
              <polygon
                id="Fill-38"
                fill="url(#linearGradient-18)"
                points="0.65158371 6.70738095 0.65158371 8.78531746 4.82986425 12.1178571 4.82986425 12.4511111 26.949095 6.88380952 26.949095 6.86420635 26.9307692 0.0815079365"
              />
            </g>
            <polygon
              id="Fill-41"
              fill="#13007C"
              points="463.355046 299 463.561151 330.355581 455.561151 331 455.561151 299.497123"
            />
            <g id="Group-45" transform="translate(383.561151, 176.000000)">
              <mask id="mask-20" fill="white">
                <polygon points="0.649693694 24.8026262 59.4088088 37.4973113 59.4088088 37.4793581 65.5124324 32.9910661 65.5124324 16.204854 0.413133133 0.0649559229" />
              </mask>
              <g id="Clip-44" />
              <polygon
                id="Fill-43"
                fill="url(#linearGradient-21)"
                points="0.649693694 24.8026262 59.4088088 37.4973113 59.4088088 37.4793581 65.5124324 32.9910661 65.5124324 16.204854 0.413133133 0.0649559229"
              />
            </g>
            <g
              id="Group-50"
              fill="#EDF1F1"
              transform="translate(383.561151, 131.000000)"
            >
              <polygon
                id="Fill-46"
                points="65.512613 51.4367943 65.512613 61.7451101 0.413313714 45.4008845 0.321217618 34.0199221 57.6212416 49.327862"
              />
              <polygon
                id="Fill-48"
                points="65.512613 20.4573056 57.6212416 24.8569747 0.0882686687 8.45820782 0.0142306306 0.0952003008"
              />
            </g>
            <g id="Group-53" transform="translate(383.561151, 200.000000)">
              <mask id="mask-23" fill="white">
                <path d="M2.27608783,178.487084 L2.27608783,178.920307 L23.3135576,178.866154 L23.3135576,146.446629 C23.3135576,145.52603 23.7253928,144.695686 24.4189162,144.009749 L24.4189162,144.009749 C24.5258864,143.901444 24.6506849,143.775087 24.7754835,143.666781 L24.7754835,143.666781 C25.0411261,143.450169 25.3281628,143.251609 25.6508562,143.08915 L25.6508562,143.08915 C27.200141,142.204653 29.3573731,141.843634 31.4789484,142.312959 L31.4789484,142.312959 L32.6556205,142.583723 C37.8775483,143.702883 41.4271757,147.042311 41.4271757,150.814961 L41.4271757,150.814961 L41.4271757,178.830052 L58.6493755,178.79395 L58.6493755,178.487084 L58.6493755,22.4906836 L58.6493755,13.1222351 L0.63766116,0.358401006 L2.27608783,178.487084 Z M27.5014404,64.0981474 L27.5014404,34.6931329 L37.8454573,36.5884837 L37.8454573,65.5061223 L27.5014404,64.0981474 Z" />
              </mask>
              <g id="Clip-52" />
              <path
                id="Fill-51"
                d="M2.27608783,178.487084 L2.27608783,178.920307 L23.3135576,178.866154 L23.3135576,146.446629 C23.3135576,145.52603 23.7253928,144.695686 24.4189162,144.009749 L24.4189162,144.009749 C24.5258864,143.901444 24.6506849,143.775087 24.7754835,143.666781 L24.7754835,143.666781 C25.0411261,143.450169 25.3281628,143.251609 25.6508562,143.08915 L25.6508562,143.08915 C27.200141,142.204653 29.3573731,141.843634 31.4789484,142.312959 L31.4789484,142.312959 L32.6556205,142.583723 C37.8775483,143.702883 41.4271757,147.042311 41.4271757,150.814961 L41.4271757,150.814961 L41.4271757,178.830052 L58.6493755,178.79395 L58.6493755,178.487084 L58.6493755,22.4906836 L58.6493755,13.1222351 L0.63766116,0.358401006 L2.27608783,178.487084 Z M27.5014404,64.0981474 L27.5014404,34.6931329 L37.8454573,36.5884837 L37.8454573,65.5061223 L27.5014404,64.0981474 Z"
                fill="url(#linearGradient-24)"
              />
            </g>
            <g id="Group-56" transform="translate(383.561151, 140.000000)">
              <mask id="mask-26" fill="white">
                <polygon points="0.3215621 25.011 57.6603671 39.8863333 57.6603671 24.9403333 57.6603671 17.9425667 57.6603671 16.107 0.0866410585 0.171666667" />
              </mask>
              <g id="Clip-55" />
              <polygon
                id="Fill-54"
                fill="url(#linearGradient-27)"
                points="0.3215621 25.011 57.6603671 39.8863333 57.6603671 24.9403333 57.6603671 17.9425667 57.6603671 16.107 0.0866410585 0.171666667"
              />
            </g>
            <g id="Group-65" transform="translate(338.561151, 175.000000)">
              <path
                id="Fill-57"
                d="M70.3984062,168.387044 C71.9734687,167.52046 74.1484687,167.159384 76.3252813,167.628783 L77.5215313,167.881537 C82.8303438,169.000873 86.4390313,172.340829 86.4390313,176.114076 L86.4390313,204.133597 L68.0222187,204.169705 L68.0222187,171.745053 C68.0222187,170.824309 68.4409062,169.993833 69.1477812,169.307788 C69.2547187,169.181411 69.3815937,169.073089 69.5102812,168.964766 C69.7803437,168.766174 70.0703437,168.567582 70.3984062,168.387044"
                fill="#13007C"
              />
              <polygon
                id="Fill-59"
                fill="#13007C"
                points="82.7955437 61.8695345 82.7955437 90.7917463 72.2812312 89.3835487 72.2812312 59.9738839"
              />
              <path
                id="Fill-61"
                d="M30.0507062,70.0661471 L30.0507062,41.270312 L18.8675812,43.0937473 L18.8675812,43.1118011 L18.8675812,71.6729366 L30.0507062,70.0661471 Z M46.6350812,203.790755 L46.6350812,204.224047 L0.45258125,204.33237 L0.45258125,203.790755 L0.45258125,33.5071715 L44.9675812,25.6357081 L46.6350812,203.790755 Z"
                fill="#EDF1F1"
              />
              <polygon
                id="Fill-63"
                fill="#EDF1F1"
                points="44.333025 0.648866959 44.7335875 0.75718985 44.9692125 25.635347 0.4524 33.5068104 0.4524 9.60355925"
              />
            </g>
            <g id="Group-68" transform="translate(338.561151, 164.000000)">
              <mask id="mask-29" fill="white">
                <polygon points="0.4482 9.76903846 0.4482 20.5820192 44.028 11.5681731 44.424 11.6772115 44.334 0.300865385 44.028 0.228173077" />
              </mask>
              <g id="Clip-67" />
              <polygon
                id="Fill-66"
                fill="url(#linearGradient-30)"
                points="0.4482 9.76903846 0.4482 20.5820192 44.028 11.5681731 44.424 11.6772115 44.334 0.300865385 44.028 0.228173077"
              />
            </g>
            <g id="Group-71" transform="translate(345.561151, 131.000000)">
              <mask id="mask-32" fill="white">
                <polygon points="0.0501576044 8.52906579 0.0501576044 16.9611107 37.8330575 8.10649917 37.9064224 8.12403306 37.8330575 0.058446281" />
              </mask>
              <g id="Clip-70" />
              <polygon
                id="Fill-69"
                fill="url(#linearGradient-33)"
                points="0.0501576044 8.52906579 0.0501576044 16.9611107 37.8330575 8.10649917 37.9064224 8.12403306 37.8330575 0.058446281"
              />
            </g>
            <g id="Group-78" transform="translate(338.561151, 140.000000)">
              <polygon
                id="Fill-72"
                fill="#EDF1F1"
                points="44.09928 0.176243551 44.33328 25.3722881 44.02728 25.3006066 0.44928 34.7088025 0.44928 19.3510428 6.94728 12.8817881 6.94728 9.2081116 44.02728 0.158323178"
              />
              <polygon
                id="Fill-74"
                fill="#13007C"
                points="29.84346 76.8034005 29.84346 105.386396 18.73746 106.981309 18.73746 78.6312786"
              />
              <polygon
                id="Fill-76"
                fill="#13007C"
                points="29.84346 76.8034005 18.73746 78.6312786 18.73746 78.6133582"
              />
            </g>
            <g id="Group-81" transform="translate(271.561151, 102.000000)">
              <mask id="mask-35" fill="white">
                <polygon points="0.102229199 39.9921603 67.2458446 56.4514266 73.7479121 49.8347283 73.7479121 46.0773234 73.7479121 37.2630014 73.7479121 26.3555299 73.7479121 21.9566168 0.102229199 0.695203804" />
              </mask>
              <g id="Clip-80" />
              <polygon
                id="Fill-79"
                fill="url(#linearGradient-36)"
                points="0.102229199 39.9921603 67.2458446 56.4514266 73.7479121 49.8347283 73.7479121 46.0773234 73.7479121 37.2630014 73.7479121 26.3555299 73.7479121 21.9566168 0.102229199 0.695203804"
              />
            </g>
            <g id="Group-84" transform="translate(271.561151, 141.000000)">
              <mask id="mask-38" fill="white">
                <path d="M0.114578755,238.494841 L67.4533223,238.330076 L67.4533223,237.786894 L67.4533223,67.0105303 L67.4533223,43.0381061 L67.4533223,32.265 L67.4533223,16.7481061 L0.114578755,0.488863636 L0.114578755,238.494841 Z M30.1478498,53.8474242 L30.1478498,27.9195455 L44.0331136,30.6173485 L44.0331136,56.6900758 L30.1478498,53.8474242 Z M34.7148718,128.915152 L34.7148718,104.091742 L44.0331136,105.359167 L44.0331136,130.309318 L34.7148718,128.915152 Z" />
              </mask>
              <g id="Clip-83" />
              <path
                id="Fill-82"
                d="M0.114578755,238.494841 L67.4533223,238.330076 L67.4533223,237.786894 L67.4533223,67.0105303 L67.4533223,43.0381061 L67.4533223,32.265 L67.4533223,16.7481061 L0.114578755,0.488863636 L0.114578755,238.494841 Z M30.1478498,53.8474242 L30.1478498,27.9195455 L44.0331136,30.6173485 L44.0331136,56.6900758 L30.1478498,53.8474242 Z M34.7148718,128.915152 L34.7148718,104.091742 L44.0331136,105.359167 L44.0331136,130.309318 L34.7148718,128.915152 Z"
                fill="url(#linearGradient-39)"
              />
            </g>
            <polygon
              id="Fill-85"
              fill="#EDF1F1"
              points="321.561151 58.5426009 321.561151 61.0538117 316.808058 64.9282511 316.71735 65 271.561151 49.9147982 271.561151 41 293.712016 48.7847534"
            />
            <g id="Group-89" transform="translate(271.561151, 50.000000)">
              <mask id="mask-41" fill="white">
                <path d="M0.113736264,48.8929825 L0.167513736,48.9111111 L44.8924451,61.945614 L44.8924451,15.3368421 L44.8207418,15.2643275 L44.7311126,15.3368421 L0.113736264,0.0906432749 L0.113736264,48.8929825 Z M24.098489,55.6730994 L24.098489,29.894152 L33.3482143,32.832807 L33.3482143,58.3380117 L24.098489,55.6730994 Z" />
              </mask>
              <g id="Clip-88" />
              <path
                id="Fill-87"
                d="M0.113736264,48.8929825 L0.167513736,48.9111111 L44.8924451,61.945614 L44.8924451,15.3368421 L44.8207418,15.2643275 L44.7311126,15.3368421 L0.113736264,0.0906432749 L0.113736264,48.8929825 Z M24.098489,55.6730994 L24.098489,29.894152 L33.3482143,32.832807 L33.3482143,58.3380117 L24.098489,55.6730994 Z"
                fill="url(#linearGradient-42)"
              />
            </g>
            <g id="Group-98" transform="translate(250.561151, 0.000000)">
              <polygon
                id="Fill-90"
                fill="#13007C"
                points="64.5158334 247.048867 64.5158334 271.945437 55.224347 270.554265 55.224347 245.784165"
              />
              <polygon
                id="Fill-92"
                fill="#13007C"
                points="64.5158334 172.468103 64.5158334 198.484838 50.6704382 195.648291 50.6704382 169.776094"
              />
              <polygon
                id="Fill-94"
                fill="#13007C"
                points="54.1073878 82.313071 54.1073878 107.733589 44.817702 105.077714 44.817702 79.3861884"
              />
              <polygon
                id="Fill-96"
                fill="#EDF1F1"
                points="20.7228608 0.37824309 44.817702 16.1508885 22.9935128 7.93032304 20.7228608 7.06309854 0.141137838 14.8319846"
              />
            </g>
            <g id="Group-101" transform="translate(271.561151, 11.000000)">
              <mask id="mask-44" fill="white">
                <polygon points="0.103265306 29.7686267 22.3099413 37.5839845 22.3099413 8.23137331 2.3968699 0.866208897 0.103265306 0.00183752418" />
              </mask>
              <g id="Clip-100" />
              <polygon
                id="Fill-99"
                fill="url(#linearGradient-45)"
                points="0.103265306 29.7686267 22.3099413 37.5839845 22.3099413 8.23137331 2.3968699 0.866208897 0.103265306 0.00183752418"
              />
            </g>
            <g
              id="Group-110"
              fill="#EDF1F1"
              transform="translate(196.561151, 6.000000)"
            >
              <polygon
                id="Fill-102"
                points="148.74561 118.457543 74.4273171 97.4563905 74.4273171 97.438286 74.4273171 92.1336846 74.4818293 92.1155802 119.817805 105.132674 148.74561 113.460717"
              />
              <polygon
                id="Fill-104"
                points="98.7406646 10.8986928 96.612872 12.709137 76.7177378 5.30442036 74.4264085 4.43540716 74.4264085 0.651578855 76.7177378 1.52059205 98.7406646 9.75811301"
              />
              <polygon
                id="Fill-106"
                points="74.4265902 97.4384671 74.4265902 97.4565715 74.4265902 136.272494 74.1903707 136.218181 6.4517 154.938174 0.19915122 145.99458 0.19915122 122.80279 0.19915122 118.40341"
              />
              <polygon
                id="Fill-108"
                points="74.4265902 92.0972947 74.4265902 92.1335036 65.8336512 94.5957076 65.8336512 68.9960271 56.4739073 71.9470511 56.4739073 97.2932694 29.1269561 105.132493 29.2377976 58.4773465 74.4265902 43.3601378"
              />
            </g>
            <g id="Group-113" transform="translate(221.561151, 41.000000)">
              <mask id="mask-47" fill="white">
                <polygon points="28.0342052 7.6224 0.0382293763 17.23376 0.0382293763 19.696 4.89134809 23.56976 49.9275654 8.872 49.9275654 0.1248" />
              </mask>
              <g id="Clip-112" />
              <polygon
                id="Fill-111"
                fill="url(#linearGradient-48)"
                points="28.0342052 7.6224 0.0382293763 17.23376 0.0382293763 19.696 4.89134809 23.56976 49.9275654 8.872 49.9275654 0.1248"
              />
            </g>
            <g id="Group-120" transform="translate(203.561151, 10.000000)">
              <polygon
                id="Fill-114"
                fill="#EDF1F1"
                points="67.9268342 0.115795186 67.9268342 30.0067211 46.0314379 37.7100087 45.8141139 37.0771095 45.8141139 8.37965069"
              />
              <path
                id="Fill-116"
                d="M24.516902,267.686959 L24.516902,240.45421 L15.3530719,241.828505 L15.3530719,241.846588 L15.1538582,268.645349 L24.516902,267.686959 Z M27.0161283,349.656449 L27.0161283,324.864883 L13.5601486,325.949853 L13.5601486,350.524425 L27.0161283,349.656449 Z M42.6453484,186.585445 L42.6453484,159.18995 L28.7184999,161.884293 L28.7184999,188.827717 L42.6453484,186.585445 Z M45.1083541,264.395883 L45.1083541,237.163134 L35.1295589,238.664009 L35.7272,265.372356 L45.1083541,264.395883 Z M67.9273776,131.794456 L67.9273776,369.493319 L1.42622346,369.656064 L0.484485986,204.993771 L0.412044642,191.142319 L0.285272289,171.088456 L0.250862651,162.155535 L0.178421306,150.437858 L67.6919432,131.740207 L67.9273776,131.794456 Z"
                fill="#EDF1F1"
              />
              <polygon
                id="Fill-118"
                fill="#13007C"
                points="59.3615508 64.5986416 59.3615508 90.16777 50.0347277 92.8621123 50.0347277 67.5461436"
              />
            </g>
            <g id="Group-123" transform="translate(247.561151, 6.000000)">
              <mask id="mask-50" fill="white">
                <polygon points="3.68171429 8.5190625 0.225657143 11.0053125 2.29822857 12.8334375 23.9291429 4.47890625 23.9291429 0.656296875" />
              </mask>
              <g id="Clip-122" />
              <polygon
                id="Fill-121"
                fill="url(#linearGradient-51)"
                points="3.68171429 8.5190625 0.225657143 11.0053125 2.29822857 12.8334375 23.9291429 4.47890625 23.9291429 0.656296875"
              />
            </g>
            <g
              id="Group-132"
              fill="#13007C"
              transform="translate(216.561151, 170.000000)"
            >
              <polygon
                id="Fill-124"
                points="31.6597031 78.0458431 31.6597031 105.282585 22.2643995 106.259201 21.665857 79.5469384"
              />
              <polygon
                id="Fill-126"
                points="29.192257 0.0610468815 29.192257 27.4605581 15.2462165 29.7031583 15.2462165 2.75578429"
              />
              <polygon
                id="Fill-128"
                points="13.5398262 165.760631 13.5398262 190.555832 0.0635508772 191.423935 0.0635508772 166.84576"
              />
              <polygon
                id="Fill-130"
                points="11.0370116 81.3372207 11.0370116 108.573963 1.65984561 109.532493 1.85935978 82.7298031"
              />
            </g>
            <g id="Group-135" transform="translate(197.561151, 98.000000)">
              <mask id="mask-53" fill="white">
                <polygon points="65.3907317 2.58272279 65.3907317 2.72967255 56.0956098 5.43171127 56.0956098 5.31966208 28.9321951 13.2733179 0.198536585 21.7229291 0.198536585 26.7375897 73.9278049 5.46661184 73.9278049 0.0845768541" />
              </mask>
              <g id="Clip-134" />
              <polygon
                id="Fill-133"
                fill="url(#linearGradient-54)"
                points="65.3907317 2.58272279 65.3907317 2.72967255 56.0956098 5.43171127 56.0956098 5.31966208 28.9321951 13.2733179 0.198536585 21.7229291 0.198536585 26.7375897 73.9278049 5.46661184 73.9278049 0.0845768541"
              />
            </g>
            <path
              id="Fill-136"
              d="M158.174047,304.684141 L158.409894,281.557359 L153.856233,282.172626 L153.856233,305.082255 L158.174047,304.684141 Z M158.972299,344.622176 L158.972299,322.870695 L154.400495,323.214521 L154.382353,323.214521 L154.382353,344.984097 L154.400495,344.984097 L158.972299,344.622176 Z M204.4182,215 L204.619577,215 L205.561151,379.782847 L122.561151,380 L122.561151,258.032463 L122.561151,230.508335 L122.778856,230.508335 L204.4182,215 Z M147.343228,345.436499 L147.343228,323.739307 L142.771424,324.173613 L142.771424,345.798421 L147.343228,345.436499 Z M165.233129,303.616473 L169.803118,303.037399 L169.803118,280.091577 L165.233129,280.743036 L165.233129,303.616473 Z M177.114375,301.806866 L181.686178,301.318272 L181.686178,278.354354 L177.114375,278.933428 L177.114375,301.806866 Z M177.114375,343.156394 L181.686178,342.884953 L181.686178,320.753455 L177.114375,321.151568 L177.114375,343.156394 Z M136.514222,306.186115 L136.514222,284.127002 L131.940605,284.742268 L131.940605,306.76519 L136.514222,306.186115 Z"
              fill="#EDF1F1"
            />
            <g id="Group-140" transform="translate(122.561151, 202.000000)">
              <mask id="mask-56" fill="white">
                <polygon points="81.2438915 0.161303657 21.6913474 12.7230525 0.0702453724 17.2903021 0.0702453724 29.5783784 0.286276367 29.5783784 81.2978993 14.0461049 81.4977279 14.0461049 81.4239173 0.161303657" />
              </mask>
              <g id="Clip-139" />
              <polygon
                id="Fill-138"
                fill="url(#linearGradient-57)"
                points="81.2438915 0.161303657 21.6913474 12.7230525 0.0702453724 17.2903021 0.0702453724 29.5783784 0.286276367 29.5783784 81.2978993 14.0461049 81.4977279 14.0461049 81.4239173 0.161303657"
              />
            </g>
            <polygon
              id="Fill-141"
              fill="#EDF1F1"
              points="143.743786 193.526082 203.326077 181 203.434573 181 203.561151 201.309101 203.378516 201.309101 143.561151 214 143.653373 193.507769"
            />
            <g id="Group-145" transform="translate(144.561151, 172.000000)">
              <mask id="mask-59" fill="white">
                <polygon points="58.9111662 0.11 0.207600767 13.9883333 0.172012064 21.6883333 0.260983821 21.7066667 58.8933718 9.16666667 59.0001379 9.16666667 58.9663287 0.11" />
              </mask>
              <g id="Clip-144" />
              <polygon
                id="Fill-143"
                fill="url(#linearGradient-60)"
                points="58.9111662 0.11 0.207600767 13.9883333 0.172012064 21.6883333 0.260983821 21.7066667 58.8933718 9.16666667 59.0001379 9.16666667 58.9663287 0.11"
              />
            </g>
            <g id="Group-161" transform="translate(0.561151, 0.000000)">
              <polygon
                id="Fill-146"
                fill="#13007C"
                points="181.041427 321.431053 181.041427 343.548304 176.482816 343.81957 176.482816 321.82891"
              />
              <polygon
                id="Fill-148"
                fill="#13007C"
                points="181.041427 279.059789 181.041427 302.008923 176.482816 302.497203 176.482816 279.63849"
              />
              <polygon
                id="Fill-150"
                fill="#13007C"
                points="169.193018 280.795713 169.193018 303.726763 164.634408 304.305464 164.634408 281.446752"
              />
              <polygon
                id="Fill-152"
                fill="#13007C"
                points="158.393995 323.54675 158.393995 345.284228 153.833576 345.645916 153.833576 323.890354"
              />
              <polygon
                id="Fill-154"
                fill="#13007C"
                points="153.29215 282.875241 157.832671 282.26037 157.597505 305.372265 153.29215 305.770122"
              />
              <mask id="mask-62" fill="white">
                <polygon points="0 388 606.729382 388 606.729382 0.378445964 0 0.378445964" />
              </mask>
              <g id="Clip-157" />
              <polygon
                id="Fill-156"
                fill="#13007C"
                points="153.816933 345.646278 153.835023 345.646278 153.835023 323.890715 153.816933 323.890715"
              />
              <polygon
                id="Fill-158"
                fill="#13007C"
                points="146.79812 324.414802 146.79812 346.098027 142.2377 346.459715 142.2377 324.848828"
              />
              <polygon
                id="Fill-159"
                fill="#EDF1F1"
                points="145.024603 151.726994 145.024603 154.439658 142.041607 158.201218 97.4305361 149.014331 97.2858183 149.068584 97.2858183 140.87634 97.3418964 140.858255 105.934516 142.811373 124.040522 146.952706"
              />
              <polygon
                id="Fill-160"
                fill="#EDF1F1"
                points="126.357092 183.410906 126.357092 183.42899 141.805717 186.213992 143.940305 186.593765 143.904125 194.189223 97.3411728 186.629934 97.3411728 178.184507 116.516281 181.638632"
              />
            </g>
            <g id="Group-164" transform="translate(97.561151, 186.000000)">
              <mask id="mask-64" fill="white">
                <polygon points="0.648275862 13.6167553 0.648275862 21.4786579 0.648275862 29.5575789 25.2827586 32.9861053 46.9099617 28.3904211 47 7.98339474 0.648275862 0.360394737" />
              </mask>
              <g id="Clip-163" />
              <polygon
                id="Fill-162"
                fill="url(#linearGradient-65)"
                points="0.648275862 13.6167553 0.648275862 21.4786579 0.648275862 29.5575789 25.2827586 32.9861053 46.9099617 28.3904211 47 7.98339474 0.648275862 0.360394737"
              />
            </g>
            <g id="Group-167" transform="translate(97.561151, 148.000000)">
              <mask id="mask-67" fill="white">
                <polygon points="0.592850275 0.0868400315 0.592850275 25.2326438 0.64842033 25.2674921 0.64842033 29.5061466 0.64842033 29.6161939 19.6480014 33.1193656 19.6480014 15.1266351 29.4014423 16.7406619 29.4014423 34.9168046 29.4014423 34.9351458 44.7100962 37.7596927 44.728022 37.612963 44.9449245 9.34915288 0.738049451 0.0318163909" />
              </mask>
              <g id="Clip-166" />
              <polygon
                id="Fill-165"
                fill="url(#linearGradient-68)"
                points="0.592850275 0.0868400315 0.592850275 25.2326438 0.64842033 25.2674921 0.64842033 29.5061466 0.64842033 29.6161939 19.6480014 33.1193656 19.6480014 15.1266351 29.4014423 16.7406619 29.4014423 34.9168046 29.4014423 34.9351458 44.7100962 37.7596927 44.728022 37.612963 44.9449245 9.34915288 0.738049451 0.0318163909"
              />
            </g>
            <g id="Group-174" transform="translate(94.561151, 112.000000)">
              <polygon
                id="Fill-168"
                fill="#13007C"
                points="41.3328005 172.154993 41.3328005 194.256806 36.7480407 194.837001 36.7480407 172.77145"
              />
              <polygon
                id="Fill-170"
                fill="#13007C"
                points="31.6393343 52.5074895 31.6393343 70.4754114 21.746001 68.6985635 21.746001 50.9119526"
              />
              <polygon
                id="Fill-172"
                fill="#EDF1F1"
                points="31.6393343 12.6553278 11.1070304 8.24947001 0.393496078 11.9482148 16.2337412 0.634407252"
              />
            </g>
            <g id="Group-177" transform="translate(105.561151, 125.000000)">
              <mask id="mask-70" fill="white">
                <polygon points="0.183241107 18.2723626 18.2249802 22.4685989 18.4412648 4.18107143 0.183241107 0.113104396" />
              </mask>
              <g id="Clip-176" />
              <polygon
                id="Fill-175"
                fill="url(#linearGradient-71)"
                points="0.183241107 18.2723626 18.2249802 22.4685989 18.4412648 4.18107143 0.183241107 0.113104396"
              />
            </g>
            <g id="Group-180" transform="translate(97.561151, 227.000000)">
              <mask id="mask-73" fill="white">
                <polygon points="0.630645161 28.2988304 24.9016129 30.9637427 24.9016129 3.39005848 0.630645161 0.344444444" />
              </mask>
              <g id="Clip-179" />
              <polygon
                id="Fill-178"
                fill="url(#linearGradient-74)"
                points="0.630645161 28.2988304 24.9016129 30.9637427 24.9016129 3.39005848 0.630645161 0.344444444"
              />
            </g>
            <g id="Group-187" transform="translate(97.561151, 215.000000)">
              <polygon
                id="Fill-181"
                fill="#EDF1F1"
                points="24.9014355 3.78184693 24.9014355 16.0574424 0.630467742 13.0157019 0.630467742 0.377994517"
              />
              <polygon
                id="Fill-183"
                fill="#13007C"
                points="13.724371 83.1026527 13.724371 106.839092 8.88082258 106.513191 8.88082258 82.6681183"
              />
              <polygon
                id="Fill-185"
                fill="#13007C"
                points="13.724371 49.2448224 13.724371 66.1011341 8.88082258 65.5760717 8.88082258 48.900816"
              />
            </g>
            <g id="Group-190" transform="translate(97.561151, 256.000000)">
              <mask id="mask-76" fill="white">
                <path d="M0.630645161,124.673913 L0.630645161,124.927536 L24.9016129,124.873188 L24.9016129,2.77173913 L0.630645161,0.108695652 L0.630645161,124.673913 Z M8.88064516,24.7644928 L8.88064516,8.07971014 L13.7241935,8.42391304 L13.7241935,25.2898551 L8.88064516,24.7644928 Z M8.88064516,65.7246377 L8.88064516,41.8641304 L13.7241935,42.3007246 L13.7241935,66.0507246 L8.88064516,65.7246377 Z" />
              </mask>
              <g id="Clip-189" />
              <path
                id="Fill-188"
                d="M0.630645161,124.673913 L0.630645161,124.927536 L24.9016129,124.873188 L24.9016129,2.77173913 L0.630645161,0.108695652 L0.630645161,124.673913 Z M8.88064516,24.7644928 L8.88064516,8.07971014 L13.7241935,8.42391304 L13.7241935,25.2898551 L8.88064516,24.7644928 Z M8.88064516,65.7246377 L8.88064516,41.8641304 L13.7241935,42.3007246 L13.7241935,66.0507246 L8.88064516,65.7246377 Z"
                fill="url(#linearGradient-77)"
              />
            </g>
            <polygon
              id="Fill-191"
              fill="#EDF1F1"
              points="106.561151 121 126.561151 125.650718 126.561151 127.220096 124.506235 129 106.561151 124.751196"
            />
            <g id="Group-195" transform="translate(94.561151, 121.000000)">
              <mask id="mask-79" fill="white">
                <polygon points="0.41755102 4.176 0.41755102 5.408 2.52057143 7.344 10.9955102 4.01955556 10.9955102 0.186666667" />
              </mask>
              <g id="Clip-194" />
              <polygon
                id="Fill-193"
                fill="url(#linearGradient-80)"
                points="0.41755102 4.176 0.41755102 5.408 2.52057143 7.344 10.9955102 4.01955556 10.9955102 0.186666667"
              />
            </g>
            <g
              id="Group-200"
              fill="#EDF1F1"
              transform="translate(29.561151, 227.000000)"
            >
              <path
                id="Fill-196"
                d="M67.6562658,153.49192 L67.6562658,153.746618 L56.8625055,153.764811 L56.8625055,153.601076 L56.8625055,134.60787 C56.8625055,124.72922 48.8378156,117.106467 39.4946932,117.106467 C38.24508,117.106467 36.9592462,117.233816 35.6915226,117.5249 C30.9448036,118.580078 26.7450166,120.908747 23.4489354,124.110667 C22.1812119,125.347772 21.0402607,126.712227 20.0441923,128.185838 C19.4465512,129.077281 18.9032411,129.986918 18.4305613,130.951132 C16.6575594,134.444136 15.6796013,138.373764 15.6796013,142.521706 L15.6796013,153.873967 L8.12759112,153.89216 L8.12759112,38.0408785 L67.6562658,28.3987335 L67.6562658,153.49192 Z"
              />
              <polygon
                id="Fill-198"
                points="67.6562658 0.345185552 67.6562658 28.3983696 8.12759112 38.0405146 0.122822575 31.4365549 0.122822575 12.9709377"
              />
            </g>
            <g id="Group-203" transform="translate(29.561151, 216.000000)">
              <mask id="mask-82" fill="white">
                <polygon points="22.4162841 10.3612847 0.122460368 15.2880339 0.122460368 24.9525174 67.6559036 12.6930556 67.6559036 0.362934028" />
              </mask>
              <g id="Clip-202" />
              <polygon
                id="Fill-201"
                fill="url(#linearGradient-83)"
                points="22.4162841 10.3612847 0.122460368 15.2880339 0.122460368 24.9525174 67.6559036 12.6930556 67.6559036 0.362934028"
              />
            </g>
            <polygon
              id="Fill-204"
              fill="#EDF1F1"
              points="97.5611511 207.840794 97.5611511 215.813567 52.5611511 226 52.5611511 197.564375 97.5611511 187 97.5611511 200.083987"
            />
            <g id="Group-208" transform="translate(47.561151, 178.000000)">
              <mask id="mask-85" fill="white">
                <polygon points="28.3214286 5.73958333 0.267857143 13.2020833 0.267857143 15.8270833 5.05357143 19.9333333 49.6607143 8.92708333 49.6607143 0.170833333 49.6607143 0.0583333333" />
              </mask>
              <g id="Clip-207" />
              <polygon
                id="Fill-206"
                fill="url(#linearGradient-86)"
                points="28.3214286 5.73958333 0.267857143 13.2020833 0.267857143 15.8270833 5.05357143 19.9333333 49.6607143 8.92708333 49.6607143 0.170833333 49.6607143 0.0583333333"
              />
            </g>
            <g
              id="Group-213"
              fill="#EDF1F1"
              transform="translate(74.561151, 125.000000)"
            >
              <polygon
                id="Fill-209"
                points="22.318006 16.1109085 22.2638369 16.1290356 22.2638369 15.1320449 22.372175 3.18628308 30.8947708 0.104675337 30.8947708 18.0686358"
              />
              <polygon
                id="Fill-211"
                points="22.2632952 49.192874 22.3174643 49.2291282 22.3174643 53.4164893 0.740129661 58.9090019 0.68596062 58.7096038 0.68596062 30.9207528 22.2632952 24.3406139"
              />
            </g>
            <g id="Group-216" transform="translate(70.561151, 140.000000)">
              <mask id="mask-88" fill="white">
                <polygon points="0.27 8.61403509 0.27 11.0701754 5.0922 14.9315789 26.6022 8.56140351 26.6022 0.614035088" />
              </mask>
              <g id="Clip-215" />
              <polygon
                id="Fill-214"
                fill="url(#linearGradient-89)"
                points="0.27 8.61403509 0.27 11.0701754 5.0922 14.9315789 26.6022 8.56140351 26.6022 0.614035088"
              />
            </g>
            <path
              id="Fill-217"
              d="M86.5611511,380.890153 L46.5611511,381 L46.5611511,369.575952 C46.5611511,365.401781 47.5110192,361.447303 49.2330948,357.932212 C49.7097878,356.9619 50.2374923,356.046512 50.8003771,355.149431 C51.7678353,353.68481 52.8760148,352.311727 54.1073252,351.048491 C57.3087324,347.826324 61.3878881,345.482929 65.9982663,344.421079 C67.2295768,344.128154 68.4784774,344 69.6921977,344 C78.7669558,344 86.5611511,351.670955 86.5611511,361.612073 L86.5611511,380.725383 L86.5611511,380.890153 Z"
              fill="#13007C"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CastelloLogoBluSVG;
